
.eCommerce-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 20px;
}
.btn-box {
    margin-top: 20px;
    text-align: right;
    .button-link {
        padding: 0;
        a {
            display: block;
            padding: 12px 20px;
            transition: all 0s;
            color: inherit;
        }
    }
}
.demo-ruleForm {
    .el-form-item {
        margin-bottom: 16px;
    }
    ::v-deep .el-form-item__label {
        font-size: 16px;
        color: #666;
    }
    ::v-deep .el-form-item__error {
        padding-top: 2px;
    }
}
.number-input {
    ::v-deep .el-input__inner {
        text-align: left;
    }
}
